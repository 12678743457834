import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { InsightsTabs } from '@/models/Enums';

import MusicInsights from '../components/insights-page/Music/MusicInsights';
import SocialsInsights from '../components/insights-page/Socials/SocialsInsights';
import UserHeader from '../components/utility/navigation/UserHeader';

const InsightsPage = () => {
  const [params, setSearchParams] = useSearchParams();
  const tab = params.get('tab');
  const platform = params.get('platform');

  useEffect(() => {
    if (tab && platform && Object.values(InsightsTabs).includes(tab as InsightsTabs)) {
      setSearchParams({ tab: tab, platform: platform }, { replace: false });
    } else {
      setSearchParams({ tab: InsightsTabs.SOCIALS }, { replace: false });
    }
  }, [platform, setSearchParams, tab]);

  return (
    <div data-testid="insights-page" className="page-content">
      <UserHeader title="PAGE-TITLES.DATA-AND-INSIGHTS" isInsights />
      {tab === InsightsTabs.SOCIALS && <SocialsInsights />}
      {tab === InsightsTabs.MUSIC && <MusicInsights />}
    </div>
  );
};

export default InsightsPage;
